<template>
  <div id="daily_view_container">
    <div v-if="!loading && columns.length" class="pan-head-icon-wrap">
      <span class="export-intip4 export-intip6" data-tool-tip="Export" @click="showOptions = true">
        <font-awesome-icon :icon="['far', 'ellipsis-h']" class="pan-head-icon" />
      </span>
      <div v-if="showOptions" class="download-options-modal">
        <div class="modal-header">
          <button class="close-btn" @click="showOptions = false">X</button>
        </div>
        <div class="options-content">
          <p>Download as:</p>
          <button class="option-btn" @click="handleDownload('pdf', 'daily_view_container')">
            PDF
          </button>
          <button class="option-btn" @click="handleDownload('csv')">CSV</button>
        </div>
      </div>
    </div>
    <div class="chart-filters">
      <div class="multi-selec-container">
        <div class="cehckbox-container">
          <b-icon-select-checkbox
            :disabled="loading"
            :options="reportTypeOptions"
            @selected-options="onChangeReportType"
          />
        </div>
        <div
          v-for="(opt, i) in reportTypeOptions"
          :key="i"
          class="m-tag-wrap"
          :class="{ disabled: loading }"
        >
          <span v-if="opt.checked" class="m-tag-itm selected"> {{ opt.name }} </span>
          <font-awesome-icon
            v-if="opt.checked"
            :icon="['fal', 'times']"
            @click="
              opt.checked = false;
              onChangeReportType(reportTypeOptions);
            "
          />
        </div>
      </div>
      <div class="cumulative">
        <k-checkbox
          id="cumulative"
          :checked="cumulative"
          name="cumulative"
          label="Cumulative"
          @change="setActive"
          @selected="setActive"
        />
      </div>
    </div>

    <div style="margin-top:50px;">
      <flip-multi-axes-line-chart
        v-if="!loading && columns.length"
        :columns="columns"
        :show-diff="showDiffPill"
      />
      <div v-else-if="!loading && !columns.length" class="txt-center">
        No data is available to show
      </div>
      <b-loading-spinner v-else class="txt-center" />
    </div>
  </div>
</template>

<script>
import { isBlank } from 'adready-api/helpers/common';
import { get } from 'vuex-pathify';
import moment from 'moment';
import { exportToPDFLineChart } from '@/util/utility-functions';
import graphDataMixin from '~/components/mixins/graph-data-mixin';
import advertiserReportsAPI from '~/api/advertiser-reports';
import { buildQueryString } from '~/helpers/global/url-helpers';
import { formatDateForAPI } from '~/util/apiDateFormat';
import {
  DAILY_VIEW_NEW_KIND_OPTIONS,
  KIND_VIEWS,
  KIND_SPEND,
  KIND_CPM,
  KIND_EVENT,
  KIND_METRIC,
  REVENUE,
  CPA,
  ROAS,
  VISITS,
  VCR,
  CTR,
  USE_PG_API_FOR_REPORT_BUILDER,
} from '~/constant';
import formatNumber, { formatNumberAsFullNumber } from '~/util/flip-numeral';
import * as util from '~/util/flip-utility-functions';

export default {
  name: 'DailyViewChartNew',
  components: {
    FlipMultiAxesLineChart: () =>
      import(
        /* webpackChunkName: "multi-axes-line-chart" */ '@/components/charts/flip-multi-axes-line-chart.vue'
      ),
    BIconSelectCheckbox: () =>
      import(
        /* webpackChunkName: "b-icon-select-checkbox" */ '~/components/elements/b-icon-select-checkbox.vue'
      ),
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
  },
  mixins: [graphDataMixin],

  data() {
    return {
      columns: [],
      reportTypeOptions: [],
      prevSelectedEvent: '',
      cumulative: true,
      showOptions: false,
      exportToPDFLineChart,
    };
  },
  computed: {
    dates: get('dashboard/dates'),
    showDiffPill: get('dashboard/dates@compareWith'),
    selectedEvent: get('dashboard/GET_SELECTED_EVENT'),
  },
  watch: {
    async showDiffPill() {
      try {
        await this.loadGraphData(this.payload);
      } catch (err) {
        console.error('error watching showDiffPill daily-view-chart', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    async selectedEvent(n) {
      this.prevSelectedEvent = n;
      if (this.prevSelectedEvent?.isFalseEvent) {
        this.payload.cumulative = false;
        this.cumulative = false;
      }
    },
  },
  async mounted() {
    try {
      this.prevSelectedEvent = this.selectedEvent;
      if (this.selectedEvent.isIncrementalReach) this.genReportTypeOptions(true);
      else this.genReportTypeOptions(false);
      this.payload.cumulative = true;
      this.cumulative = true;
      await this.loadGraphData(this.payload, true);
    } catch (err) {
      console.error('error mounting daily-view-chart', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
    }
  },
  methods: {
    handleDownload(format, elementId = '') {
      this.showOptions = false;
      if (format === 'csv') {
        this.download();
      } else {
        this.exportToPDFLineChart(elementId, 'landscape');
      }
    },

    download() {
      if (!this.loading && !this.columns.length) {
        return;
      }

      try {
        const lines = [];
        const header = ['Date'];

        const { currentDays } = this.columns[0];
        currentDays.forEach((day) => {
          lines.push([day]);
        });

        this.columns.forEach((row) => {
          const { exportRaw } = row;
          const columnsToRead = [];
          exportRaw.forEach((entry, i) => {
            const { data } = entry[1];
            if (i === 0) {
              entry[0].data.forEach((col, j) => {
                let colName = col;
                if (col === 'Video Completions') {
                  colName = 'Completions';
                }
                if (col === 'VCR') {
                  colName = 'VCR / LTR';
                }
                //  Remove duplicate columns
                if (!header.includes(colName) && colName !== '-') {
                  header.push(colName);
                  columnsToRead.push(j);
                }
              });
            }

            data.forEach((col, k) => {
              if (columnsToRead.includes(k)) {
                lines[i].push(col);
              }
            });
          });
        });

        const csvData = [header, ...lines];
        util.downloadCSVFile(csvData, 'export_daily_view.csv');
      } catch (err) {
        console.error('error downloading file ->', err);
        throw err;
      }
    },
    setActive(val) {
      this.cumulative = val.checked;
      this.onChangeReportType(this.reportTypeOptions);
    },
    genReportTypeOptions(isChangeReportType) {
      if (isChangeReportType || this.selectedEvent.metric !== 'incrementalReachPercentage') {
        const reportTypeOptions = [...DAILY_VIEW_NEW_KIND_OPTIONS];
        const eventOption = reportTypeOptions.find((opt) => opt.id === KIND_EVENT);
        if (eventOption) {
          eventOption.name =
            this.selectedEvent.eventLabel === 'Video Completions'
              ? 'Completions'
              : this.selectedEvent.eventLabel;
          eventOption.children = this.selectedEvent.subEventLabels || [];
        }

        // if (this.selectedEvent.metric !== 'incrementalReachPercentage') {
        const metricOption = reportTypeOptions.find((opt) => opt.id === KIND_METRIC);
        if (metricOption) {
          metricOption.name = this.selectedEvent.metric;
          if (this.selectedEvent.isFalseEvent) {
            metricOption.name = this.selectedEvent.isVcrEvent ? 'Completion Rate' : 'CTR';
          } else if (!this.selectedEvent.isRevenueEvent) {
            metricOption.name = `${this.selectedEvent.eventLabel} ${this.selectedEvent.metric}`;
          }
        }

        // } else {
        //   reportTypeOptions = reportTypeOptions.filter((opt) => opt.id !== KIND_METRIC);
        // }

        let checkedItemName = '';
        if (!isBlank(this.selectedEvent.eventLabel) && this.selectedEvent.isRevenueEvent)
          checkedItemName = 'ROAS';
        else if (this.selectedEvent?.isFalseEvent) {
          checkedItemName = this.selectedEvent.isVcrEvent ? 'Completion Rate' : 'CTR';
        } else if (
          !isBlank(this.selectedEvent.eventLabel) &&
          this.selectedEvent.eventLabel === 'Incremental Reach'
        )
          checkedItemName = 'Incremental Reach';
        else if (!isBlank(this.selectedEvent.eventLabel) && !this.selectedEvent.isRevenueEvent)
          checkedItemName = `${this.selectedEvent.eventLabel} CPA`;
        else checkedItemName = 'IMPRESSIONS';

        reportTypeOptions.forEach((item) => {
          item.value = item.name;
          item.checked = item.name.toUpperCase() === checkedItemName.toUpperCase();
        });
        this.reportTypeOptions = reportTypeOptions
          .filter((rts) => rts.name !== 'Incremental Reach')
          .filter((rts) => rts.name !== 'Incremental Reach incrementalReachPercentage')
          .filter((rts) => !!rts.name.trim());
      }
    },
    async onChangeReportType(reportTypeOptions) {
      this.reportTypeOptions = reportTypeOptions;
      try {
        this.payload.cumulative = this.cumulative;
        await this.loadGraphData({ ...this.payload }, true);
      } catch (err) {
        console.error('error updating selected option ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    async loadGraphData(payload, isChangeReport = false) {
      if (payload.event !== this.prevSelectedEvent.eventLabel) {
        this.genReportTypeOptions(isChangeReport);
      }
      this.loading = true;
      try {
        const promises = [];
        const legendNames = [];
        const legendValueTypes = [];
        const legendColors = [
          'rgb(0, 171, 183)',
          'rgb(226, 61, 86)',
          'rgb(255, 143, 58)',
          'rgb(255, 209, 81)',
          'rgb(212, 232, 133)',
        ];
        this.reportTypeOptions.forEach((rTV) => {
          if (!rTV.checked) {
            return;
          }
          const p = { ...payload };

          // Advertiser details required for further processing
          if (!this.advertiser) {
            return;
          }
          if (this.dates.compareWith) {
            p.compareStartDate = formatDateForAPI(this.dates.compareStartDate);
            p.compareEndDate = formatDateForAPI(this.dates.compareEndDate);
          }
          p.cumulative = !this.cumulative ? false : payload.cumulative;
          switch (rTV.id) {
            case KIND_VIEWS:
            case KIND_SPEND:
              delete p.pixel;
              delete p.event;
              delete p.category;
              delete p.subCategory;
              delete p.showUniques;
              delete p.attribution;
              delete p.methodology;
              delete p.conversionWindow;

              p.kind = rTV.id;

              promises.push(this.fetchDailyImpressions(p));
              legendNames.push(rTV.name);
              legendValueTypes.push(util.getValueFormat(p.kind));
              break;

            case KIND_EVENT:
              p.kind = (this.selectedEvent.isRevenueEvent ? REVENUE : VISITS).toLowerCase();
              if (this.selectedEvent?.isFalseEvent) {
                p.kind = this.selectedEvent.isVcrEvent ? 'videoCompletions' : 'clicks';
                promises.push(this.fetchDailyImpressions(p));
              } else {
                promises.push(this.fetchDailyConversions(p));
              }
              legendNames.push(rTV.name);
              legendValueTypes.push(util.getValueFormat(p.kind));
              break;

            case KIND_METRIC:
              p.kind = (this.selectedEvent.isRevenueEvent ? ROAS : CPA).toLowerCase();
              if (this.selectedEvent?.isFalseEvent) {
                p.kind = this.selectedEvent.isVcrEvent ? VCR.toLowerCase() : CTR.toLowerCase();
              } else if (this.selectedEvent?.isIncrementalReach) {
                p.kind = 'incrementalReachPercentage';
              }
              promises.push(this.fetchDailyPerformance(p));
              legendNames.push(rTV.name);
              legendValueTypes.push(util.getValueFormat(p.kind));

              break;

            case KIND_CPM:
              p.kind = rTV.id;
              p.category = isBlank(p.category) ? 'none' : p.category;
              promises.push(this.fetchDailyPerformance(p));
              legendNames.push(rTV.name);
              legendValueTypes.push(util.getValueFormat(p.kind));
              break;

            default:
              break;
          }
        });
        if (!this.selectedEvent?.isIncrementalReach) {
          const columnData = await Promise.all(promises);
          columnData.forEach((c, ci) => {
            c.legendName = legendNames[ci];
            c.legendColor = legendColors[ci];
            c.legendValueTypes = legendValueTypes[ci];
          });
          this.columns = columnData;
        } else {
          return;
        }
      } catch (err) {
        console.error('error fetching daily view API ->', err);

        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        this.loading = false;
      }
    },
    validateParams(payload, advertiser, extraKeys = []) {
      const advertiserId = advertiser ? advertiser.id : null;
      if (isBlank(advertiserId)) {
        return false;
      }
      const keys = ['kind', 'client', 'advertiser', 'startDate', 'endDate', ...extraKeys];
      return !util.isAnyBlank(payload, keys);
    },
    async fetchDailyConversions(payload) {
      const params = buildQueryString(payload);

      try {
        const keys = ['xandrId', 'pixel', 'category'];
        const dataValidated = this.validateParams(payload, this.advertiser, keys);
        let data = { breakup: [] };
        if (dataValidated) {
          data = await advertiserReportsAPI.conversionsDaily(this.advertiser.id, params);
        }
        return this.processResults(data, payload);
      } catch (err) {
        console.error('error in fetching daily conversions ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
        throw err;
      }
    },
    async fetchDailyImpressions(payload) {
      const params = buildQueryString(payload);

      try {
        let data = {};
        if (USE_PG_API_FOR_REPORT_BUILDER) {
          data = await advertiserReportsAPI.impressionsDaily(this.advertiser.id, params);
        } else {
          data = await advertiserReportsAPI.mdspImpressionsDaily(this.advertiser.id, params);
        }
        return this.processResults(data, payload);
      } catch (err) {
        console.error('error in fetching daily impressions ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
        throw err;
      }
    },
    async fetchDailyPerformance(payload) {
      payload.conversionWindow = this.conversionWindow;
      const params = buildQueryString(payload);

      try {
        const keys = ['xandrId', 'pixel', 'category'];
        const dataValidated = this.validateParams(payload, this.advertiser, keys);
        let data = { breakup: [] };
        if (dataValidated) {
          if (USE_PG_API_FOR_REPORT_BUILDER) {
            data = await advertiserReportsAPI.performanceDaily(this.advertiser.id, params);
          } else {
            data = await advertiserReportsAPI.mdspPerformanceDaily(this.advertiser.id, params);
          }
        }
        return this.processResultsMetrics(data, payload);
      } catch (err) {
        console.error('error in fetching daily performance ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
        throw err;
      }
    },
    getFormattedChangeValue(change, reverseColors = false) {
      let css = '';
      if (change === 0) {
        css = 'zero';
      } else {
        const arrowCss = change < 0 ? 'caret-down' : 'caret-up';
        const changeMultiplier = reverseColors ? -1 : 1;
        const changeMultiplierApplied = change * changeMultiplier;
        css = changeMultiplierApplied < 0 ? 'negative-color' : 'positive-color';
        css = `${css}-${arrowCss}`;
      }
      const changeValue = formatNumber(Math.abs(change), 'percentage');
      return `<span class='${css}'>${changeValue}</span>`;
    },
    async processResults(res, payload) {
      const days = [];
      const days1 = [];
      const last7Days = [];
      const prev7Days = [];

      const tooltip = [];
      const exportRaw = [];
      let eventId = payload.kind;
      if (
        payload.kind === REVENUE.toLowerCase() ||
        payload.kind === VISITS.toLowerCase() ||
        payload.kind === 'videoCompletions'
      ) {
        eventId = KIND_EVENT;
      }
      const reportType = this.reportTypeOptions.find((opt) => opt.id === eventId);
      const colLabel = reportType?.name || '-';

      const exportRawHeaders = ['Date', colLabel];

      res.breakup.forEach((br, i) => {
        days.push(br.key);
        last7Days.push(br.value?.currentValue);
        prev7Days.push(br.value?.compareValue);
        const tooltipRows = [];
        const exportRawRows = [];
        const currentDate = moment(br.key).format('MM/DD/YYYY');

        exportRawRows.push({
          data: exportRawHeaders,
        });
        tooltipRows.push({
          meta: { css: 'lctt-wrap-type1' },
          data: [
            currentDate,
            formatNumber(br.value?.currentValue, util.getValueFormat(payload.kind)),
          ],
        });
        exportRawRows.push({
          data: [
            br.key,
            formatNumberAsFullNumber(br.value?.currentValue, util.getValueFormat(payload.kind)),
          ],
        });
        if (this.showDiffPill) {
          const compareDateRaw = moment(payload.compareStartDate).add(i, 'days');
          const compareDate = moment(compareDateRaw).format('MM/DD/YYYY');

          tooltipRows.push({
            meta: { css: 'lctt-wrap-compare' },
            data: [
              compareDate,
              formatNumber(br.value?.compareValue, util.getValueFormat(payload.kind)),
            ],
          });
          tooltipRows.push({
            meta: { css: 'lctt-wrap-change' },
            data: ['Change', this.getFormattedChangeValue(Math.round(br.value?.change))],
          });
        }

        tooltip.push(tooltipRows);
        exportRaw.push(exportRawRows);
        if (this.showDiffPill) {
          days1.push(
            moment(payload.compareStartDate)
              .add(i, 'days')
              .format('YYYY-MM-DD')
          );
        }
      });
      if (this.showDiffPill) {
        return {
          currentDays: days,
          prevDays: days1,
          currentDaysData: last7Days,
          prevDaysData: prev7Days,
          tooltip,
          exportRaw,
        };
      }
      return {
        currentDays: days,
        currentDaysData: last7Days,
        tooltip,
        exportRaw,
      };
    },

    async processResultsMetrics(res, payload) {
      const { kind } = payload;
      const days = [];
      const days1 = [];
      const last7Days = [];
      const prev7Days = [];

      let col1Key = 'spend';
      let col2Key = 'impressions';
      let col1Header = 'TOTAL SPEND';
      let col2Header = 'TOTAL IMPS';
      let col3Header = 'CPM';
      let col1RawHeader = 'Spend';
      let col2RawHeader = 'Impressions';
      let col3RawHeader = 'CPM';
      if (payload.kind === 'vcr') {
        col1Key = 'videoImpressions';
        col2Key = 'videoCompletions';
        col1Header = 'VIDEO IMPRESSIONS';
        col2Header = 'COMPLETIONS';
        col3Header = 'VCR / LTR';
        col1RawHeader = 'Video Impressions';
        col2RawHeader = 'Completions';
        col3RawHeader = 'VCR / LTR';
      } else if (payload.kind === 'ctr') {
        col1Key = 'clicks';
        col2Key = 'impressions';
        col1Header = 'CLICKS';
        col2Header = 'IMPRESSIONS';
        col3Header = 'CTR';
        col1RawHeader = 'Clicks';
        col2RawHeader = 'Impressions';
        col3RawHeader = 'CTR';
      } else if (payload.kind !== 'cpm') {
        col2Key = this.selectedEvent.isRevenueEvent ? 'revenue' : 'visits';
        col2Header = this.selectedEvent.isRevenueEvent
          ? 'TOTAL REV'
          : this.selectedEvent.eventLabel.toUpperCase();
        col3Header = this.selectedEvent.metric;
        col2RawHeader = this.selectedEvent.isRevenueEvent
          ? 'Revenue'
          : this.selectedEvent.eventLabel;
        col3RawHeader = this.selectedEvent.metric;
      }

      const tooltip = [];
      const exportRaw = [];
      const tooltipHeaders = ['DATE', col1Header, col2Header, col3Header];
      const exportRawHeaders = ['Date', col1RawHeader, col2RawHeader, col3RawHeader];

      res.breakup.forEach((br, i) => {
        const col1ObjRef = br.value[col1Key];
        const col2ObjRef = br.value[col2Key];
        const valueObjRef = br.value[kind];

        days.push(br.key);
        last7Days.push(valueObjRef?.currentValue);
        prev7Days.push(valueObjRef?.compareValue);
        const tooltipRows = [];
        const exportRawRows = [];
        const currentDate = moment(br.key).format('MM-DD-YYYY');

        tooltipRows.push({
          meta: { css: 'lctt-wrap-header lctt-wrap-header-custom' },
          data: tooltipHeaders,
        });
        exportRawRows.push({
          data: exportRawHeaders,
        });
        tooltipRows.push({
          meta: { css: 'lctt-wrap-type1 lctt-wrap-custom' },
          data: [
            currentDate,
            formatNumber(col1ObjRef?.currentValue, util.getValueFormat(col1Key)),
            formatNumber(col2ObjRef?.currentValue, util.getValueFormat(col2Key)),
            formatNumber(valueObjRef?.currentValue, util.getValueFormat(kind)),
          ],
        });
        exportRawRows.push({
          data: [
            br.key,
            formatNumberAsFullNumber(col1ObjRef?.currentValue, util.getValueFormat(col1Key)),
            formatNumberAsFullNumber(col2ObjRef?.currentValue, util.getValueFormat(col2Key)),
            formatNumberAsFullNumber(valueObjRef?.currentValue, util.getValueFormat(kind)),
          ],
        });
        if (this.showDiffPill) {
          const compareDateRaw = moment(payload.compareStartDate).add(i, 'days');
          const compareDate = moment(compareDateRaw).format('MM-DD-YYYY');
          tooltipRows.push({
            meta: { css: 'lctt-wrap-compare lctt-wrap-compare-custom' },
            data: [
              compareDate,
              formatNumber(col1ObjRef?.compareValue, util.getValueFormat(col1Key)),
              formatNumber(col2ObjRef?.compareValue, util.getValueFormat(col2Key)),
              formatNumber(valueObjRef?.compareValue, util.getValueFormat(kind)),
            ],
          });
          tooltipRows.push({
            meta: { css: 'lctt-wrap-change lctt-wrap-change-custom' },
            data: [
              'Change',
              this.getFormattedChangeValue(Math.round(col1ObjRef?.change)),
              this.getFormattedChangeValue(Math.round(col2ObjRef?.change)),
              this.getFormattedChangeValue(Math.round(valueObjRef?.change), this.reverseColors),
            ],
          });
        }

        tooltip.push(tooltipRows);
        exportRaw.push(exportRawRows);
        if (this.showDiffPill) {
          days1.push(
            moment(payload.compareStartDate)
              .add(i, 'days')
              .format('YYYY-MM-DD')
          );
        }
      });
      if (this.showDiffPill) {
        return {
          currentDays: days,
          prevDays: days1,
          currentDaysData: last7Days,
          prevDaysData: prev7Days,
          tooltip,
          exportRaw,
        };
      }
      return {
        currentDays: days,
        currentDaysData: last7Days,
        tooltip,
        exportRaw,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.cumulative {
  line-height: 31px;
}
.multi-tag-wrap {
  display: inline-block;
  z-index: 999;
}
.m-tag-itm {
  display: none;
  padding: 5px 27px 4px 7px;
  background-color: var(--primarycolor);
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.05em;
  position: relative;
  margin: 2px;
  cursor: pointer;
  opacity: 1;
  color: var(--buttontext);
}
.m-tag-itm:hover {
  opacity: 0.8;
}
.m-tag-itm.selected {
  display: inline-block;
  margin-right: 8px;
}
.light-theme {
  .m-tag-itm {
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;
  }
  .m-tag-wrap {
    svg {
      color: white;
    }
  }
}
@media screen {
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .opt-btn:hover {
    color: var(--primarycolor);
  }
}
.m-tag-wrap {
  position: relative;
  svg {
    font-weight: 300;
    position: absolute;
    color: var(--overtext);
    opacity: 0.7;
    font-size: 13px;
    right: 15px;
    top: 10px;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
  }
}
.numero-diff > * {
  display: inline-block;
}

.diff-pill {
  margin-left: 0.8rem;
}
.muted-text {
  color: #687982;
}
.cur-vs {
  display: inline-block;
  height: 85px;
  margin: 4px 18px -20px 24px;
  font-size: 11px;
  vertical-align: top;
  border-left: 1px solid #999;
  opacity: 0.3;
  transform: rotate(11deg);
}
.cur-val {
  margin-bottom: 2px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.loading-spinner {
  width: 100px;
  height: 100px;
}
.container-chart {
  position: absolute;
  top: 160px;
}
.chart-filters {
  display: flex;
  justify-content: space-between;
  .multi-selec-container {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    .cehckbox-container {
      height: 28px;
      width: 28px;
      margin-right: 8px;
    }
  }
  .chart-widget {
    position: absolute;
    right: 55px;
  }
  .flp-opt-panel {
    top: 40px;
    right: 30px;
  }
}
.cur-amt {
  margin: 0px 10px 10px 35px;
  font-size: 48px;
  font-weight: 300;
}
::v-deep .chart-filters .dropdown .dropdown-menu li {
  font-size: 14px;
}
::v-deep .light-theme .chart-filters .dropdown .dropdown-menu li {
  font-size: 13px;
}
::v-deep .cur-amt span.big {
  font-size: 48px;
  font-weight: 300;
  line-height: 1em;
}
.cur-val.muted-text {
  font-size: 11px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  opacity: 0.5;
}
.cur-amt.muted-text {
  width: 156px;
  color: #687982;
  .cur-val {
    margin-bottom: 2px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
  .numero-diff {
    font-size: 48px;
    font-weight: 300;
    line-height: 48px;
  }
  .prev-val {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    vertical-align: middle;
    opacity: 0.5;
  }
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.check-cumulative {
  font-size: 12px;
}
::v-deep .checkbox-btn svg.checkbox-active {
  color: var(--primarycolor);
}
::v-deep .apexcharts-legend-series {
  pointer-events: none !important;
}
.pan-head-icon-wrap {
  position: absolute;
  right: 17px;
  top: 11px;
}
.pan-head-icon {
  color: #97a7bb;
}
.pan-head-icon:hover {
  color: var(--primarycolor) !important;
}
.light-theme {
  .export-intip4::after {
    background-color: rgba(55, 55, 60, 0.95);
    color: #fff;
  }
  .export-intip6[data-tool-tip]::after {
    background-color: rgba(55, 55, 60, 0.95);
    color: #fff;
    font-weight: 400 !important;
  }
}
.export-intip4 {
  position: relative;
  top: 0px;
  display: inline-block;
}
.export-intip4[data-tool-tip]::after {
  position: absolute;
  bottom: 0%;
  left: 0;
  z-index: 99;
  display: inline-block;
  width: 100px;
  padding: 10px 12px;
  margin-left: -65px;
  font-size: 12px;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: center;
  pointer-events: none;
  content: attr(data-tool-tip);
  background-color: #35363c;
  border-radius: 3px;
  -webkit-transition: ease 0.3s;
  transition: ease 0.3s;
  -webkit-transform: scale(0);
  transform: scale(0);
  transform-origin: bottom center;
}
.export-intip4[data-tool-tip]::after {
  width: 140px;
  margin-left: -75px;
  font-size: 12px;
  color: #222;
  pointer-events: none;
  background-color: rgba(245, 245, 250, 0.9);
}
.export-intip6[data-tool-tip]::after {
  background-color: rgba(245, 245, 250, 0.9);
  color: #222;
  width: 70px;
  bottom: 100%;
  margin-left: -35px !important;
  font-size: 12px;
  font-weight: 600;
  padding: 6px;
  text-align: center;
  pointer-events: none;
}
.export-intip6[data-tool-tip]:hover::after {
  transform: scale(1);
  bottom: 145%;
}
::v-deep .lctt-wrap-header .tooltip-label-1-3 {
  padding-left: 15px;
  padding-right: 15px;
}

.download-options-modal {
  position: absolute;
  top: 200%;
  left: -970%;
  transform: translate(-20%, -30%);
  background-color: #fff;
  padding: 8px 14px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 3;
  text-align: justify;
  width: 160px;
}

.options-content p {
  padding: 4px;
  font-size: 14px;
  color: #333;
}

.options-content button {
  margin: 5px;
  padding: 8px 15px;
  font-size: 14px;
  background-color: #00abb7;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.options-content button:hover {
  background-color: #007b8f;
}
.option-btn {
  padding: 10px;
}
.close-btn {
  background: white;
}

.modal-header {
  text-align: right;
}
</style>
